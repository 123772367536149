@import './variables/variables.scss';

button {
    z-index: 0;
}

.primary-button {
    width: 100%;
    border-radius: 15px;
    background: $primary-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 20px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
       background: $disable-color;
       &:hover {
        background: $disable-color;
        cursor: not-allowed;
        }
    }
}

.primary-button:hover{
    background: $dark-primary-color;
}

.secondary-button {
    width: 100%;
    border-radius: 15px;
    background: $secondary-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 20px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
        background: $disable-color;
        &:hover {
            background: $disable-color;
            cursor: not-allowed;
        }
    }
}

.secondary-button:hover{
    background: $dark-secondary-color;
}

.active-button {
    width: 100%;
    border-radius: 15px;
    background: $active-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 20px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.active-button:hover {
    background: $dark-active-color;
}

.disable-button {
    width: 100%;
    border-radius: 15px;
    background: $disable-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 20px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.search-button {
    border-radius: 10px;
    background: $primary-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin:0 !important;
    
    .anticon{
        svg{
            fill: #FFFFFF !important;
            }
    }
}

.search-button:hover{
    background: $dark-primary-color;
}

.ant-collapse {
  width: 100% !important;
  background-color: #fafafa00 !important;
}

.ant-collapse-content {
  background-color: rgba(255, 255, 255, 0) !important; 
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  text-align: left !important;
  font-size: 14px;
  font-weight: 600;
  background-color: $filter-header;
  color: #FFFFFF !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 10px !important;
}

.exit-button,
.exit-button:hover {
    font-size: 18px !important;
    color: $disable-color !important;
    background: #ffffff00;
    //background-image: linear-gradient(-90deg, #ffffff00 0%, rgba($primary-color, 0.5) 100%);
    border-radius: 10px;
    padding-left: 10px !important;
    float: right!important;
}

.link{
    color: $detail-color;
    font-size: 18px;
}